import axios from 'axios';
import config from '../../../config';

const getUsersListStart = () => {
  return {
    type: 'GET_USERS_LIST_START',
  };
};

const getUsersListSuccess = ({ data }) => {
  return {
    type: 'GET_USERS_LIST_SUCCESS',
    data,
  };
};

const getUsersListFail = () => {
  return {
    type: 'GET_USERS_LIST_FAIL',
  };
};

export function getUsersList() {
  return (dispatch, getState) => {
    dispatch(getUsersListStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/user/all`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(getUsersListSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to post user', ex);
        dispatch(getUsersListFail());
      });
  };
}
