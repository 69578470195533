// import { google } from 'googleapis';
import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import useDrivePicker from 'react-google-drive-picker';
import PageHeader from '../../../components/PageHeader';
import Button from '../../../components/Button';

const API_KEY = 'AIzaSyD_oSMpMNMai88KGnyrWPoF3dzeWuis9i8';
const CLIENT_ID =
    '170517857904-qi2ahrnn1cd03krtpvpmk905kuakfnc2.apps.googleusercontent.com';

export const Upload = () => {
    const [openPicker, data, authResponse] = useDrivePicker({
        onCancel: () =>
            console.log('User closed picker with close/cancel button'),
    });

    const handleOpenPicker = () => {
        openPicker({
            clientId: CLIENT_ID,
            developerKey: API_KEY,
            viewId: null,
            token: 'ya29.a0ARrdaM8cbmwi5KYwsF9OGmptD7Zq1qmSI9lREf1ShGkIaQ56zJoVsHEA_qYZXZjucV3utW1CpOMX5KLoa-trqfR832OhRY7jYVGEfqJkFCJvV0LC91zJvV3zyv68W2XwV36mYxTPcgoP0oMZXw-55YMoi6av', // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: false,
            supportDrives: false,
            multiselect: false,
        });

        console.log(data);
    };

    return (
        <div>
            <PageHeader
                icon={<ion-icon name="cloud-upload-outline"></ion-icon>}
                title="Upload Files"
            ></PageHeader>
            <div className="upload_button" onClick={handleOpenPicker}>
                Click to upload file
            </div>
        </div>
    );
};
