import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";
import StatusScreen from "../../../components/StatusScreen";
import Loader from "../../../components/Loader";

import {
  postUser,
  getUserRole,
  resetPostUserStatus,
  getUserById,
  updateUser,
} from "./../actions";
import { getCompaniesList } from "./../../companies/actions";

import "./index.scss";
import { getFundsList } from "../../funds/actions";
import { getLpMetrics, getLpsList } from "../../LPDashboard/actions/index";

const emailValidate = (email) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  if (!pattern.test(email)) {
    return false;
  }

  return true;
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const userAdd = useSelector((state) => state.userAdd);

  const roles = useSelector((state) => state.userAdd.userRoles);
  const selectedUser = useSelector((state) => state.userAdd.selectedUser);

  const companyList = useSelector((state) => state.companies.list);
  const fundList = useSelector((state) => state.lps.list);

  const { selectedLpMetrics, isFetchingSelectedLpMetrics } = useSelector(
    (state) => state.lps
  );

  useEffect(() => {
    dispatch(getCompaniesList());
    dispatch(getFundsList());
    dispatch(getUserRole());
    dispatch(getLpsList());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
    companyId: [],
    fundId: [],
    lpId: [],
    whitelistedTabs: [],
  });
  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);
  const [errors, setErrors] = useState({});
  const [isEdit, setEditState] = useState();

  console.log(formData, 'formData');

  useEffect(() => {
    console.log(formData, "formdata");
    dispatch(getLpMetrics(formData.lpId));
  }, [formData.lpId]);

  useEffect(() => {
    if (params.userId) {
      setEditState(true);
      dispatch(getUserById(params.userId));
    }
  }, [params.userId, dispatch]);

  useEffect(() => {
    if (userAdd.isPostUserSuccess && userAdd.user) {
      setStatus(true);
      setStatusType("success");
      setStatusMessage(`${userAdd.user.name} is successfully created`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          onClick={() => {
            setStatus(false);
            setFormData({
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              role: "",
              companyId: [],
              lpId: [],
              fundId: [],
            });
          }}
        >
          Add Another User
        </Button>,
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            history.push("/users");
          }}
        >
          Not Now
        </Button>,
      ]);
      dispatch(resetPostUserStatus());
    } else if (userAdd.isPostUserFail) {
      setStatus(true);
      setStatusType("error");
      setStatusMessage(`Failed to create user`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetPostUserStatus());
    }

    if (userAdd.isUpdatingUserSuccess && userAdd.updatedUser) {
      setStatus(true);
      setStatusType("success");
      setStatusMessage(`${userAdd.updatedUser.name} is successfully updated`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            history.push("/users");
          }}
        >
          Go to list
        </Button>,
      ]);
      dispatch(resetPostUserStatus());
    } else if (userAdd.isUpdatingUserFail) {
      setStatus(true);
      setStatusType("error");
      setStatusMessage(`Failed to update user`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetPostUserStatus());
    }
  }, [dispatch, history, userAdd]);

  useEffect(() => {
    if (selectedUser && selectedUser.id && isEdit) {
      setFormData({
        name: selectedUser.name,
        email: selectedUser.email,
        password: selectedUser.name,
        confirmPassword: selectedUser.name,
        role: selectedUser.role,
        companyId: selectedUser.companyId,
        fundId: selectedUser.fundId,
        lpId: selectedUser.lpId,
        whitelistedTabs: selectedUser.whitelistedTabs
      });
    }
  }, [selectedUser, isEdit]);

  const validate = (values) => {
    let err = {};
    if (!values.name) {
      err.name = "*Please enter a name";
    }

    if (!values.email) {
      err.email = "*Please enter a email";
    } else if (!emailValidate(values.email)) {
      err.email = "*Please enter a valid email address";
    }

    if (!values.password) {
      err.password = "*Please enter a password";
    }

    if (!values.confirmPassword || values.password !== values.confirmPassword) {
      err.confirmPassword = `*The two password that you've provided do not match`;
    }

    if (!values.role) {
      err.role = "*Please select a user role";
    }

    if (
      values.role === "lp_admin" &&
      (!values.lpId || !values.lpId.length)
    ) {
      err.lpId = "*Please select at least one lp";
    }
    return err;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    console.log("namaste");

    const err = validate(formData);
    setErrors(err);

    console.log(err,'err');

    if (!Object.keys(err).length) {
      let postData = { ...formData };
      delete postData.confirmPassword;

      if (isEdit) {
        dispatch(updateUser(params.userId, postData));
      } else {
        dispatch(postUser(postData));
      }
    }

    return false;
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: "",
    });
  };

  if (userAdd.isFetchingUserByID) {
    return (
      <div className="app-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="user-add">
      {status ? (
        <StatusScreen
          message={statusMessage}
          type={statusType}
          actions={statusActions}
        />
      ) : (
        <>
          <PageHeader
            icon={<ion-icon name="person-add-outline"></ion-icon>}
            title={isEdit ? "Edit user" : "Create a new user"}
            subtitle={
              isEdit
                ? "Edit existing user of your organization"
                : "Invite new users to your organization"
            }
          />
          <div className="user-add__form">
            <form onSubmit={handleFormSubmit} noValidate>
              <div className="user-add__form__inputs">
                <div className="form-control">
                  <label className="labelText">
                    Name
                    <p className="error">*</p>
                  </label>
                  <Input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    value={formData.name}
                    error={errors.name}
                  />
                </div>
                <div className="form-control">
                  <label className="labelText">
                    Email
                    <p className="error">*</p>
                  </label>
                  <Input
                    type="email"
                    name="email"
                    onChange={handleInputChange}
                    value={formData.email}
                    error={errors.email}
                    disabled={isEdit}
                  />
                </div>
                <div className="form-control">
                  <label className="labelText">
                    Password
                    <p className="error">*</p>
                  </label>
                  <Input
                    type="password"
                    name="password"
                    onChange={handleInputChange}
                    value={formData.password}
                    error={errors.password}
                    disabled={isEdit}
                  />
                </div>
                <div className="form-control">
                  <label className="labelText">
                    Confirm Password
                    <p className="error">*</p>
                  </label>
                  <Input
                    type="password"
                    name="confirmPassword"
                    onChange={handleInputChange}
                    value={formData.confirmPassword}
                    error={errors.confirmPassword}
                    disabled={isEdit}
                  />
                </div>
                <div className="form-control">
                  <label className="labelText">
                    Role
                    <p className="error">*</p>
                  </label>
                  <Select
                    options={roles || []}
                    name="role"
                    onChange={handleInputChange}
                    value={formData.role}
                    error={errors.role}
                  />
                </div>
                {formData.role === "lp_admin" ? (
                  <>
                    <div className="form-control">
                      <label className="labelText">
                        LPs
                        <p className="error">*</p>
                      </label>
                      <Select
                        options={fundList}
                        isMulti={false}
                        name="lpId"
                        onChange={handleInputChange}
                        value={formData.lpId}
                        error={errors.lpId}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                      />
                    </div>
                    {formData.lpId && isFetchingSelectedLpMetrics && (
                      <div className="app-loader">
                        <Loader />
                      </div>
                    )}
                    {formData.lpId && selectedLpMetrics?.sheets && (
                      <div className="form-control">
                        <label className="labelText">
                          Select Tabs
                          <p className="error">*</p>
                        </label>
                        <Select
                          options={selectedLpMetrics.sheets}
                          isMulti={true}
                          name="whitelistedTabs"
                          onChange={handleInputChange}
                          value={formData.whitelistedTabs}
                          error={errors.whitelistedTabs}
                          getOptionLabel={(option) => option?.title}
                          getOptionValue={(option) => option?.title}
                        />
                      </div>
                    )}
                  </>
                ) : formData.role !== "gp_admin" ? (
                  <div className="form-control">
                    <label className="labelText">
                      Entity
                      <p className="error">*</p>
                    </label>
                    <Select
                      options={companyList}
                      isMulti={true}
                      name="companyId"
                      onChange={handleInputChange}
                      value={formData.companyId}
                      error={errors.companyId}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />
                  </div>
                ) : null}
              </div>
              <div className="user-add__form__action">
                <Button
                  type="submit"
                  size="lg"
                  disabled={userAdd.isPostingUser || userAdd.isUpdatingUser}
                >
                  {isEdit ? "Update" : "Create"}
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
