import React, { useState } from 'react';
import BlankState from '../../../components/BlankState';
import NoData from './../../../assets/images/no-data.svg';
import './index.scss';
import 'react-tabs/style/react-tabs.css';
import CompaniesDetailView from './companiesDetailView';
import CompaniesListView from './companiesListView';

export default () => {
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  // if (false) {
  //   return (
  //     <div className="dashboard__blank">
  //       <BlankState
  //         text="No data available"
  //         icon={<img src={NoData} alt="No data"></img>}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="dashboard column flex">
      {selectedCompany ? <CompaniesDetailView setSelectedCompany={setSelectedCompany} selectedCompany={selectedCompany}/> : <CompaniesListView setSelectedCompany={setSelectedCompany}/> }
    </div>
  );
};
