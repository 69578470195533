import axios from "axios";
import config from "../../../config";
import * as xlsx from "xlsx";
import { getFundsList } from "./../../funds/actions";
import { saveAs } from "file-saver";

const postFundStart = () => {
  return {
    type: "POST_FUND_START",
  };
};

const postFundSuccess = ({ data }) => {
  return {
    type: "POST_FUND_SUCCESS",
    data,
  };
};

const postFundFail = () => {
  return {
    type: "POST_FUND_FAIL",
  };
};

export function postFund(data) {
  return (dispatch, getState) => {
    dispatch(postFundStart());

    const { login } = getState();
    // let myData = data.sheets['FUND'].rows;
    // console.log(myData);
    // const ws = xlsx.utils.json_to_sheet(myData.map(d => d.name));
    // const wb = {
    //     Sheets: {
    //         data: ws,
    //     },
    //     SheetNames: ['data'],
    // };
    // const xlBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
    // const EXCEL_TYPE =
    //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    // ((bf, fn) => {
    //     const data = new Blob([bf], { type: EXCEL_TYPE });
    //     saveAs(data, fn);
    // })(xlBuffer, 'file');

    // console.log(xlBuffer);

    // axios
    //     .post(
    //         'https://sheet.best/api/sheets/b3c046a4-5934-4f74-8c41-c8ed40735915'
    //     )
    //     .then((res) => {
    //         console.log('res');
    //     });
    axios
      .post(`${config.API_URL}/funds/create`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(postFundSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log("Failed to post fund", ex);
        dispatch(postFundFail());
      });
  };
}

export const resetPostFundStatus = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_POST_FUND_STATUS", payload: false });
  };
};

export const getFundById = (companyId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "GET_FUND_BY_ID_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/funds/${companyId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "GET_FUND_BY_ID",
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log("Failed to fetch fund", ex);
        dispatch({
          type: "GET_FUND_BY_ID",
          payload: ex,
          error: true,
        });
      });
  };
};

// update company
export const updateFund = (fundId, data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_FUND_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .put(`${config.API_URL}/funds/${fundId}`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "UPDATE_FUND",
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log("Failed to update fund", ex);
        dispatch({
          type: "UPDATE_FUND",
          payload: ex,
          error: true,
        });
      });
  };
};

// delete company
export const deleteFund = (fundId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "DELETE_FUND_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .delete(`${config.API_URL}/funds/${fundId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "DELETE_FUND",
          payload: resp.data,
        });

        dispatch(getFundsList());
      })
      .catch((ex) => {
        console.log("Failed to update fund", ex);
        dispatch({
          type: "DELETE_FUND",
          payload: ex,
          error: true,
        });
      });
  };
};
