import axios from "axios";
import config from "../../../config";

import { getLpsList } from "../../LPDashboard/actions/index";

const postLpStart = () => {
  return {
    type: "POST_LP_START",
  };
};

const postLpSuccess = ({ data }) => {
  return {
    type: "POST_LP_SUCCESS",
    data,
  };
};

const postLpFail = () => {
  return {
    type: "POST_LP_FAIL",
  };
};

export function postLp(data) {
  return (dispatch, getState) => {
    dispatch(postLpStart());

    const { login } = getState();
    axios
      .post(`${config.API_URL}/lps/create`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(postLpSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log("Failed to post lp", ex);
        dispatch(postLpFail());
      });
  };
}

export const resetPostLpStatus = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_POST_LP_STATUS", payload: false });
  };
};

export const getLpById = (lpId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "GET_LP_BY_ID_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/lps/${lpId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "GET_LP_BY_ID",
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log("Failed to fetch lp", ex);
        dispatch({
          type: "GET_LP_BY_ID",
          payload: ex,
          error: true,
        });
      });
  };
};

// update company
export const updateLp = (lpId, data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_LP_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .put(`${config.API_URL}/lps/${lpId}`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "UPDATE_LP",
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log("Failed to update lp", ex);
        dispatch({
          type: "UPDATE_LP",
          payload: ex,
          error: true,
        });
      });
  };
};

// delete company
export const deleteLp = (lpId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "DELETE_LP_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .delete(`${config.API_URL}/lps/${lpId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "DELETE_LP",
          payload: resp.data,
        });

        dispatch(getLpsList());
      })
      .catch((ex) => {
        console.log("Failed to update company", ex);
        dispatch({
          type: "DELETE_LP",
          payload: ex,
          error: true,
        });
      });
  };
};
