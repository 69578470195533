import React, { useState } from 'react';
import BlankState from '../../../components/BlankState';
import NoData from './../../../assets/images/no-data.svg';
import './index.scss';
import 'react-tabs/style/react-tabs.css';
import FundDetailView from './fundDetailView';
import FundListView from './fundList';

export default () => {
    const [selectedFund, setSelectedFund] = useState(undefined);
    // if (false) {
    //   return (
    //     <div className="dashboard__blank">
    //       <BlankState
    //         text="No data available"
    //         icon={<img src={NoData} alt="No data"></img>}
    //       />
    //     </div>
    //   );
    // }
    return (
        <div className="dashboard column flex">
            {selectedFund ? (
                <FundDetailView
                    setSelectedFund={setSelectedFund}
                    selectedFund={selectedFund}
                />
            ) : (
                <FundListView setSelectedFund={setSelectedFund} />
            )}
        </div>
    );
};
