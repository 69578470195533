export default (
  state = {
    lp: {},
    selectedLp: {},
    isPostLpSuccess: false,
    isPostLpFail: false,
    updatedLp: {},
    deletedLp: {},
    isUpdatingLp: false,
    isPostingLp: false,
    isUpdatingLpFail: false,
    isUpdatingLpSuccess: false,
    isDeleteingLpSuccess: false,
    isDeleteingLpFail: false,
  },
  action
) => {
  switch (action.type) {
    case "POST_LP_START":
      return {
        ...state,
        isPostingLp: true,
        isPostLpSuccess: false,
        isPostLpFail: false,
      };

    case "POST_LP_SUCCESS":
      return {
        ...state,
        isPostingLp: false,
        isPostLpSuccess: true,
        lp: action.data,
        selectedLp: {},
      };

    case "POST_LP_FAIL":
      return { ...state, isPostLpFail: true, isPostingLp: false };

    case "RESET_POST_LP_STATUS":
      return {
        ...state,
        isPostLpSuccess: false,
        isPostLpFail: false,
        isUpdatingLpFail: false,
        isUpdatingLpSuccess: false,
        isDeleteingLpSuccess: false,
        isDeleteingLpFail: false,
      };

    case "GET_LP_BY_ID_LOADING":
      return {
        ...state,
        isFetchingLpByID: true,
      };

    case "GET_LP_BY_ID":
      return {
        ...state,
        selectedLp: !action.error ? action.payload : {},
        isFetchingLpByID: false,
      };

    case "UPDATE_LP_LOADING":
      return {
        ...state,
        isUpdatingLp: true,
        isUpdatingLpSuccess: false,
        isUpdatingLpFail: false,
      };

    case "UPDATE_LP":
      return {
        ...state,
        updatedLp: !action.error ? action.payload : {},
        isUpdatingLp: false,
        isUpdatingLpSuccess: !action.error ? true : false,
        isUpdatingLpFail: action.error ? true : false,
      };

    case "DELETE_LP_LOADING":
      return {
        ...state,
        isDeleteingLp: true,
        isDeleteingLpSuccess: false,
        isDeleteingLpFail: false,
      };

    case "DELETE_LP":
      return {
        ...state,
        deletedLp: !action.error ? action.payload : {},
        isDeleteingLp: false,
        isDeleteingLpSuccess: !action.error ? true : false,
        isDeleteingLpFail: action.error ? true : false,
      };

    default:
      return state;
  }
};
