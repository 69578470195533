import React, { useState, useEffect } from "react";
import cx from "classnames";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";

import Button from "../../../components/Button";
import StatusScreen from "../../../components/StatusScreen";
import Loader from "../../../components/Loader";

import { postGp, resetPostGpStatus, getGpById, updateGp } from "./../actions";
import { getTemplates } from "./../../templates/actions";

import "./index.scss";
import Input from "../../../components/Input";

const FORM = "form";
const TEMPLATE = "template";
const emailValidate = (email) => {
  var pattern =
    /^((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)\s*[,]{0,1}\s*)+$/i;

  if (!pattern.test(email)) {
    return false;
  }

  return true;
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const gpAdd = useSelector((state) => state.gpAdd);

  const templateList = useSelector((state) => state.templates.list || []);

  // const selectedFund = useSelector((state) => state.gpAdd.selectedFund || {});

  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);
  const [errors, setErrors] = useState({});
  const [openIndex, setOpenIndex] = useState(0);
  const [isEdit, setEditState] = useState(false);
  const [formData, setFormData] = useState({});
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedTemplate, setTemplate] = useState(null);
  const [fundName, setFundName] = useState("");
  const [fundUrl, setFundUrl] = useState("");
  const [frame, setFrame] = useState(null);

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  const handleSubmitClick = (e) => {
    e.preventDefault();

    console.log(selectedTemplate, "event");

    if (isEdit && params.gpId) {
      dispatch(updateGp(params.gpId, formData));
    } else {
      // console.log(formData);
      dispatch(
        postGp({
          basic: {
            name: fundName,
            googleDataStudioURL: fundUrl,
          },
          sheets: {
            ["FUND"]: {
              rows: [...selectedTemplate.templateJson],
            },
          },
        })
      );
    }
  };

  if (gpAdd?.gp?.id && gpAdd.isPostGpSuccess) {
    return (
      <StatusScreen
        message={`Created ${gpAdd.gp.name} successfully`}
        type="success"
        actions={[
          <Button
            type="button"
            size="lg"
            onClick={() => {
              setFundName("");
              setTemplate(null);
              dispatch(resetPostGpStatus());
            }}
          >
            Add Another GP
          </Button>,
          <Button
            type="button"
            size="lg"
            variant="outlined"
            onClick={() => {
              setFundName("");
              setTemplate(null);
              dispatch(resetPostGpStatus());
              history.push("/gp-dashboard");
            }}
          >
            Not Now
          </Button>,
        ]}
      />
    );
  }

  if (gpAdd.isFetchingFundByID) {
    return (
      <div className="app-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="company-add">
      <PageHeader
        icon={<ion-icon name="business-outline"></ion-icon>}
        title={isEdit ? "Edit GP" : "Create a new GP"}
        subtitle={isEdit ? "Edit GP info" : "GP Registration"}
      />
      <div className="company-add__form__card">
        {/* <div className="company-add__form__card__buttons">
          <Button
            variant={frame === TEMPLATE ? "" : "outlined"}
            size="sm"
            className="company-add__form__card__header__link"
            onClick={() => setFrame(TEMPLATE)}
          >
            Click to select template
          </Button>

          <Button
            variant={frame === FORM ? "" : "outlined"}
            size="sm"
            className="company-add__form__card__header__link"
            onClick={() => setFrame(FORM)}
          >
            Click to open form
          </Button>
        </div> */}

        <div className="company-add__form__card__frame">
          <Input
            type="text"
            label="Name"
            onChange={(e) => setFundName(e.target.value)}
          />
          <div style={{ height: "15px" }}></div>
          <Input
            type="text"
            label="DataStudio Link"
            onChange={(e) => setFundUrl(e.target.value)}
          />          
          <div className="template-list">
            {templateList && !templateList.length ? (
              <p className="bodyText">No existing templates available</p>
            ) : (
              <ul>
                <label>Select Template</label>
                <br />
                <br />
                {templateList.map((template) => {
                  return (
                    <li
                      key={template.id}
                      className={cx({
                        selected:
                          selectedTemplate &&
                          selectedTemplate.id === template.id,
                      })}
                    >
                      {/* eslint-disable-next-line */}
                      <a onClick={() => setTemplate(template)}>
                        {template.templateName}
                      </a>
                      {selectedTemplate &&
                      selectedTemplate.id === template.id ? (
                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          <div className="template-list__template__buttons">
            <Link
              to={`/templates/edit/${selectedTemplate?.id}`}
              target="_blank"
            >
              <Button
                disabled={!selectedTemplate}
                variant={"outlined"}
                size="sm"
                className="company-add__form__card__header__link"
              >
                View template
              </Button>
            </Link>
          </div>
        </div>
        <div>
          <Button
            type="button"
            size="lg"
            onClick={handleSubmitClick}
            disabled={gpAdd.isUpdatingFund || gpAdd.isPostingLp}
          >
            {gpAdd.isUpdatingLp || gpAdd.isPostingLp ? (
              "Loading..."
            ) : (
              <>{isEdit ? "Update" : "Submit"}</>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
