import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import Button from "./../../../components/Button";
import PageHeader from "../../../components/PageHeader";
import GlobalFilter from "../../../components/GlobalFilter";
import BlankState from "../../../components/BlankState";

import User from "./../../../assets/images/user.svg";

import { getUsersList } from "./../actions";
import { deleteUser } from "./../../usersAdd/actions";

import "./index.scss";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector((state) => state.users) || [];

  const data = React.useMemo(() => users.list, [users.list]);

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  const handleRowEdit = (userId) => (event) => {
    event.preventDefault();
    history.push(`/users/edit/${userId}`);
  };

  const handleRowDelete = (userId) => (event) => {
    event.preventDefault();
    if (window.confirm("Are you sure you wish to delete this user?")) {
      dispatch(deleteUser(userId));
    }
  };

  const translate = (value) => {
    // VC Portfolio Admin

    console.log(value, "value");
    // return role.label;

    if (value?.value === "gp_admin") return "GP Admin";
    if (value?.value === "gp_user") return "GP User";

    return value ? value.label : null;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ value }) => {
          return translate(value);
        },
      },
      // {
      //   Header: "Whitelisted Tabs",
      //   accessor: "whitelistedTabs",
      //   Cell: ({ value }) => {
      //     return value && value.join(", ");
      //   },
      // },
      {
        Header: "",
        accessor: "id",

        Cell: ({ value }) => (
          <div className="users__table__actions">
            <Button
              variant="text-link"
              size="xs"
              onClick={handleRowEdit(value)}
              className="users__table__edit"
            >
              <ion-icon name="pencil"></ion-icon>
            </Button>
            <Button
              variant="text-link"
              size="xs"
              onClick={handleRowDelete(value)}
              className="users__table__edit"
            >
              <ion-icon name="trash-outline"></ion-icon>
            </Button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <div className="users">
      <PageHeader
        icon={<ion-icon name="people-outline"></ion-icon>}
        title="Users"
        subtitle="All registered users"
      >
        <Link to="/users/add">
          <Button type="button">Add User</Button>
        </Link>
      </PageHeader>
      {users && users.list && users.list.length ? (
        <div className="users__table">
          <div className="users__table__filter">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>

          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <BlankState
          text="No users available"
          icon={<img src={User} alt="no user" />}
        />
      )}
    </div>
  );
};
