import axios from "axios";
import config from "../../../config";

import { getGpsList } from "../../GPDashboard/actions/index";

const postGpStart = () => {
  return {
    type: "POST_GP_START",
  };
};

const postGpSuccess = ({ data }) => {
  return {
    type: "POST_GP_SUCCESS",
    data,
  };
};

const postGpFail = () => {
  return {
    type: "POST_GP_FAIL",
  };
};

export function postGp(data) {
  return (dispatch, getState) => {
    dispatch(postGpStart());

    const { login } = getState();
    axios
      .post(`${config.API_URL}/gps/create`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(postGpSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log("Failed to post lp", ex);
        dispatch(postGpFail());
      });
  };
}

export const resetPostGpStatus = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_POST_GP_STATUS", payload: false });
  };
};

export const getGpById = (lpId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "GET_GP_BY_ID_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .get(`${config.API_URL}/gps/${lpId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "GET_GP_BY_ID",
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log("Failed to fetch gp", ex);
        dispatch({
          type: "GET_GP_BY_ID",
          payload: ex,
          error: true,
        });
      });
  };
};

// update company
export const updateGp = (gpId, data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_GP_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .put(`${config.API_URL}/gps/${gpId}`, data, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "UPDATE_GP",
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log("Failed to update gp", ex);
        dispatch({
          type: "UPDATE_GP",
          payload: ex,
          error: true,
        });
      });
  };
};

// delete company
export const deleteGp = (gpId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "DELETE_GP_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .delete(`${config.API_URL}/gps/${gpId}`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch({
          type: "DELETE_GP",
          payload: resp.data,
        });

        dispatch(getGpsList());
      })
      .catch((ex) => {
        console.log("Failed to update gp", ex);
        dispatch({
          type: "DELETE_GP",
          payload: ex,
          error: true,
        });
      });
  };
};
