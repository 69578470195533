import React, { useMemo, useState } from 'react';
import './index.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Button from '../../../components/Button';
import { useTable, useGlobalFilter, useFilters } from 'react-table';
import format from 'date-fns/format';
import Modal from '../../../components/Modal';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ModalBody } from 'react-bootstrap';

export default ({ setSelectedLP, selectedLP }) => {
  // const selectedLP = useSelector((state) => state.layout.fund);
  const [modalState, setModalState] = useState(false)
  const location = useLocation();
  const { url } = useRouteMatch();
  const history = useHistory();

  const response = require('../mockData.json')
  const taxReturns = React.useMemo(() => response.taxReturns, [response.taxReturns]);
  const quaterlyStatements = React.useMemo(() => response.quaterlyStatements, [response.quaterlyStatements]);
  const gpCommentary = React.useMemo(() => response.gpCommentary, [response.gpCommentary]);
  const capitalCalls = React.useMemo(() => response.capitalCalls, [response.capitalCalls]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Limited Partner',
        accessor: 'limitedPartner',
      },
      {
        Header: 'Document Link',
        accessor: 'documentLink',
        Cell: ({ value }) => (
          <div className='primary editLink cursor pointer' onClick={() => {
            window.open(value);
          }}>Link</div>
        ),
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <div>{new Date(value).toLocaleString()}</div>
        ),
      },
      {
        accessor: 'id',
        Cell: () => (
          <div className="company__table__actions flex row flexCenter cursor pointer">
            <div className='primary editLink'>Archive</div>
            <ion-icon name="delete"></ion-icon>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  const tabsData = [{
    label: 'Tax Returns',
    addButtonLabel: 'Add Tax Return +'
  }, {
    label: 'Quaterly Statements',
    addButtonLabel: 'Add Quaterly Statements +'
  }, {
    label: 'GP Commentary',
    addButtonLabel: 'Add GP Commentary +'
  },
  {
    label: 'Capital Calls',
    addButtonLabel: 'Add Capital Call +'
  }];
  // const [selectedTab, setSelectedTab] = useState(tabsData[0]);
  const taxReturnsTableProps = useTable(
    {
      columns,
      data: taxReturns
    },
    useFilters,
    useGlobalFilter
  );
  const quaterlyStatementsTableProps = useTable(
    {
      columns,
      data: quaterlyStatements
    },
    useFilters,
    useGlobalFilter
  );
  const gpCommentaryTableProps = useTable(
    {
      columns,
      data: gpCommentary
    },
    useFilters,
    useGlobalFilter
  );
  const capitalCallsTableProps = useTable(
    {
      columns,
      data: capitalCalls
    },
    useFilters,
    useGlobalFilter
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = taxReturnsTableProps;

  const setSelectedTab = (tabsData) => {
    const path = tabsData.label.toLowerCase().split(" ").join("-")
    history.replace(`/lp-documents/${path}`);
  }

  const { selectedTab, selectedIndex } = useMemo(() => {
    const paths = location.pathname.split("/")
    const selectedTabRoute = paths?.[paths.length - 1].split("-").join(" ");
    let selectedIndex = tabsData.findIndex(e => e.label.toLowerCase() === selectedTabRoute)

    if (paths.length === 2 || selectedIndex === -1) {
      history.replace(`/lp-documents/tax-returns`);
      selectedIndex = 0
    }

    let selectedTab = tabsData[selectedIndex]
    console.log(selectedIndex, selectedTab)
    return { selectedTab, selectedIndex }
  }, [location.pathname])

  return (
    <div className="fundDetail dashboard column flex">
      <div className='row alignCenter justifySpaceBetween flex mb48'>
        <div className='inlineFlex row alignCenter backIcon'>
          <ion-icon name="arrow-back-outline" onClick={() => {
            setSelectedLP(undefined)
          }}></ion-icon>
          <div className='f24'>{'Documents'}</div>
        </div>
        <div className='inlineFlex'>
          {
            selectedTab.addButtonLabel && selectedTab.addButtonLabel.length
              ?
              <Button onClick={() => setModalState(true)} >
                {selectedTab.addButtonLabel}
              </Button>
              :
              null
          }
        </div>
      </div>

      <div className='tabs'>
        <Tabs selectedIndex={selectedIndex} onSelect={(index) => {
          setSelectedTab(tabsData[index]);
        }}>
          <TabList className='mb24 noPadding none'>
            {
              tabsData && tabsData.length && tabsData.map((tab) => {
                return <Tab>{tab.label}</Tab>
              })
            }
          </TabList>

          <TabPanel>
            <table {...taxReturnsTableProps.getTableProps()}>
              <thead>
                {taxReturnsTableProps.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...taxReturnsTableProps.getTableBodyProps()}>
                {taxReturnsTableProps.rows.map((row) => {
                  taxReturnsTableProps.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table {...quaterlyStatementsTableProps.getTableProps()}>
              <thead>
                {quaterlyStatementsTableProps.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...quaterlyStatementsTableProps.getTableBodyProps()}>
                {quaterlyStatementsTableProps.rows.map((row) => {
                  quaterlyStatementsTableProps.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table {...gpCommentaryTableProps.getTableProps()}>
              <thead>
                {gpCommentaryTableProps.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...gpCommentaryTableProps.getTableBodyProps()}>
                {gpCommentaryTableProps.rows.map((row) => {
                  gpCommentaryTableProps.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table {...capitalCallsTableProps.getTableProps()}>
              <thead>
                {capitalCallsTableProps.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...capitalCallsTableProps.getTableBodyProps()}>
                {capitalCallsTableProps.rows.map((row) => {
                  capitalCallsTableProps.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>

      <Modal
        onClose={() => setModalState(false)}
        open={modalState}
        actions={[
          <Button
            type="button"
            size="lg"
            variant="link"
            onClick={() => setModalState(false)}
          >
            Cancel
          </Button>
        ]}
        >
        <ModalHeader>{selectedTab.addButtonLabel}</ModalHeader>
        <ModalBody>
          <div className="upload_button" onClick={null}>
            Click to upload file
          </div>
        </ModalBody>
      </Modal>

    </div>
  );
};
