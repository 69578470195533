import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Button from "../../../components/Button";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import format from "date-fns/format";
import Modal from "../../../components/Modal";
import { getCompanyMetrics } from "./../actions";

export default ({ setSelectedCompany, selectedCompany }) => {
  const {
    selectedCompanyMetrics,
    isFetchingSelectedCompanyMetrics,
  } = useSelector((state) => state.companies);
  const [modalVisibility, setModalVisibility] = useState(false);
  //   const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState({
    label: "",
    addButtonLabel: null,
    columns: [],
    data: [],
  });
  const dispatch = useDispatch();

  // const data = React.useMemo(
  //   () => selectedFund.companies,
  //   [selectedFund.companies]
  // );

  useEffect(() => {
    dispatch(getCompanyMetrics(selectedCompany.id));
  }, [dispatch, selectedCompany.id]);

  const generateObject = (row, keys) => {
    const result = {};

    keys.forEach((key, index) => {
      result[key] = row[index];
    });

    console.log(result, "result");

    return result;
  };

  // console.log(isFetchingSelectedFundMetrics, "isFetchingSelectedFundMetrics");

  useEffect(() => {
    if (selectedCompanyMetrics?.sheets?.length > 0) {
      const tabsData = selectedCompanyMetrics?.sheets?.map((sheet) => ({
        label: sheet.title,
        addButtonLabel: undefined,
        columns: sheet.header.map((h) => ({ Header: h, accessor: h })),
        data: sheet.dataRows.map((row) => generateObject(row, sheet.header)),
      }));
      tabsData.unshift({
        label: "Dashboard",
        columns: [],
        data: null,
        type: "url",
      });
      setTabs(tabsData);
      setSelectedTab(tabsData[0]);
    }
  }, [selectedCompanyMetrics]);

  console.log(tabs, "tabsData");

  // [
  // {
  //   label: "Metrics Dashboard",
  //   addButtonLabel: undefined,
  // },
  // {
  //   label: "Companies",
  //   addButtonLabel: "Add Company +",
  // },
  // {
  //   label: "Advisory Entity ( AE )",
  //   addButtonLabel: "Add AE +",
  // },
  // {
  //   label: "Entities / LLC",
  //   addButtonLabel: "Add Entities / LLC +",
  // },
  // {
  //   label: "ME",
  //   addButtonLabel: "Add ME +",
  // },
  // ];

  // console.log("selectedFund", selectedFund, "selectedCompany", selectedCompany);
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: "Name",
  //       accessor: "name",
  //     },
  //     {
  //       Header: "% STAKE",
  //       accessor: "stake",
  //     },
  //     {
  //       Header: "Amount Invested",
  //       accessor: "investedAmount",
  //     },
  //     {
  //       Header: "FMV of Company",
  //       accessor: "fmv",
  //     },
  //     {
  //       Header: "fmv of amount invested",
  //       accessor: "investedAmountFmv",
  //     },
  //     {
  //       accessor: "id",
  //       Cell: (props) => {
  //         return (
  //           <>
  //             <Button
  //               variant="outlined"
  //               size="s"
  //               onClick={() => {
  //                 setModalVisibility(true);
  //                 setSelectedCompany(props.data[props.row.index]);
  //               }}
  //             >
  //               View Cap Table
  //             </Button>
  //           </>
  //         );
  //       },
  //     },
  //   ],
  //   // eslint-disable-next-line
  //   []
  // );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: selectedTab?.columns,
      data: selectedTab?.data,
    },
    useFilters,
    useGlobalFilter
  );

  console.log(rows, "rows");

  if (isFetchingSelectedCompanyMetrics) return <p>Loading</p>;

  return (
    <div className="fundDetail dashboard column flex">
      <div className="row alignCenter justifySpaceBetween flex mb48">
        <div className="inlineFlex row alignCenter backIcon">
          <ion-icon
            name="arrow-back-outline"
            onClick={() => {
              setSelectedCompany(undefined);
            }}
          ></ion-icon>
          <div className="f24">{selectedCompany.name}</div>
        </div>
        <div className="inlineFlex">
          {selectedTab?.addButtonLabel && selectedTab.addButtonLabel.length ? (
            <Button type="submit">{selectedTab.addButtonLabel}</Button>
          ) : null}
        </div>
      </div>
      <div className="tabs">
        <Tabs
          onSelect={(index) => {
            setSelectedTab(tabs[index]);
          }}
        >
          <TabList className="mb24 noPadding">
            {tabs &&
              tabs.length &&
              tabs.map((tab) => {
                return <Tab>{tab.label}</Tab>;
              })}
          </TabList>

          <TabPanel>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};
