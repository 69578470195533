import React, { useState } from 'react';
import BlankState from '../../../components/BlankState';
import NoData from './../../../assets/images/no-data.svg';
import './index.scss';
import 'react-tabs/style/react-tabs.css';
import LPDetailView from './LPDetailView';
import LPListView from './LPListView';

export default () => {
  const [selectedLP, setSelectedLP] = useState(undefined);
  // if (false) {
  //   return (
  //     <div className="dashboard__blank">
  //       <BlankState
  //         text="No data available"
  //         icon={<img src={NoData} alt="No data"></img>}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="dashboard column flex">
      {selectedLP ? <LPDetailView setSelectedLP={setSelectedLP} selectedLP={selectedLP}/> : <LPListView setSelectedLP={setSelectedLP}/> }
    </div>
  );
};
