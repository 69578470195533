export default (
  state = {
    list: [],
    selectedGpMetrics: {},
    isFetchingSelectedGpMetrics: true,
  },
  action
) => {
  switch (action.type) {
    case "GET_GPS_LIST_START":
      return { ...state, isFetchingGpsList: true };

    case "GET_GPS_LIST_SUCCESS":
      return {
        ...state,
        isFetchingGpsList: false,
        list: action.data,
      };

    case "GET_GPS_LIST_FAIL":
      return { ...state, isFetchingGpsList: false, list: [] };

    case "GET_GP_METRICS_START":
      return {
        ...state,
        isFetchingSelectedGpMetrics: true,
        selectedGpMetrics: {},
      };

    case "GET_GP_METRICS_FAIL":
      return {
        ...state,
        isFetchingSelectedGpMetrics: false,
        selectedGpMetrics: {},
      };

    case "GET_GP_METRICS_SUCCESS":
      return {
        ...state,
        isFetchingSelectedGpMetrics: false,
        selectedGpMetrics: action.data,
      };

    case "SEND_REMINDER_LOADING":
      return {
        ...state,
        isSendingReminder: true,
        isSendingReminderSuccess: false,
        isSendingReminderFail: false,
      };

    case "SEND_REMINDER":
      return {
        ...state,
        isSendingReminder: false,
        isSendingReminderSuccess: !action.error ? true : false,
        isSendingReminderFail: action.error ? true : false,
      };

    case "RESET_REMINDER_STATUS":
      return {
        ...state,
        isSendingReminder: false,
        isSendingReminderSuccess: false,
        isSendingReminderFail: false,
      };
    default:
      return state;
  }
};
