import axios from 'axios';
import config from '../../../config';

const API_URL = "https://portfolio.primevp.in/api/v1";
const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyYjdlMTZhMC02NmZmLTExZWMtOThhOC0zM2I3ZDQ5ZmNiZDYiLCJpYXQiOjE2NTg5MTYxNDUsImV4cCI6MTY5MDQ1MjE0NSwiYXVkIjoiYXBwLnByaW1ldnAuaW4iLCJpc3MiOiJhcGkucHJpbWV2cC5pbiJ9.RLTGvZM6GwVtnUPRNHzf-o5lL7hj30Jbqs3CI6V83So`;


const getFinancialMetricsStart = () => {
  return {
    type: 'GET_FINANCIAL_METRICS_START',
  };
};

const getFinancialMetricsSuccess = ({ data }) => {
  return {
    type: 'GET_FINANCIAL_METRICS_SUCCESS',
    data,
  };
};

const getFinancialMetricsFail = () => {
  return {
    type: 'GET_FINANCIAL_METRICS_FAIL',
  };
};

export function getFinancialMetrics(companyId) {
  return (dispatch, getState) => {
    dispatch(getFinancialMetricsStart());

    const { login } = getState();

    axios
      .get(`${API_URL}/metrics/read/${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        dispatch(getFinancialMetricsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to get Finacial metrics', ex);
        dispatch(getFinancialMetricsFail());
      });
  };
}
