import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import format from "date-fns/format";

import Button from "./../../../components/Button";
import PageHeader from "../../../components/PageHeader";
import GlobalFilter from "../../../components/GlobalFilter";
import BlankState from "../../../components/BlankState";

import { getGpsList, sendReminder, resetReminderStatus } from "./../actions";
// import { deleteLP } from "./../../companyAdd/actions";
import Company from "./../../../assets/images/company.svg";

import "./index.scss";
import { deleteFund } from "../../fundsAdd/actions";

export default ({ setSelectedLP }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const lps = useSelector((state) => state.gps);
  console.log("lps: ", lps);
  const data = React.useMemo(() => lps.list, [lps.list]);

  useEffect(() => {
    dispatch(getGpsList());
  }, [dispatch]);

  useEffect(() => {
    if (!lps.isSendingReminder && lps.isSendingReminderSuccess) {
      alert("Reminder is sent successfully");
      dispatch(resetReminderStatus());
    } else if (!lps.isSendingReminder && lps.isSendingReminderFail) {
      alert("Failed to send reminder");
      dispatch(resetReminderStatus());
    }
  }, [
    lps.isSendingReminder,
    lps.isSendingReminderSuccess,
    lps.isSendingReminderFail,
    dispatch,
  ]);

  const handleRowEdit = (lpId) => (event) => {
    event.preventDefault();

    history.push(`/gps/edit/${lpId}`);
  };

  const handleRowDelete = (lpId) => (event) => {
    event.preventDefault();

    if (window.confirm("Are you sure you wish to delete this fund?")) {
      dispatch(deleteFund(lpId));
    }
  };

  const handleSendEmail = (lpId) => (event) => {
    event.preventDefault();
    let ccPartner = false;

    if (window.confirm("Do you want to cc to company partner?")) {
      ccPartner = true;
    }

    if (window.confirm("Do you want to proceed with sending reminder email?")) {
      dispatch(sendReminder(lpId, ccPartner));
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (props) => (
          <div onClick={() => setSelectedLP(props.data[props.row.index])}>
            {props.value}
          </div>
        ),
      },
      {
        // Investor Name
        Header: "",
        accessor: "ownerName",
        Cell: ({ value }) => (value ? <>{value}</> : "--"),
      },
      {
        // Partner Name
        Header: "",
        accessor: "partnerEmail",
        Cell: ({ value }) => (value ? <>{value}</> : "--"),
      },
      {
        // Last metric date
        Header: "",
        accessor: "lastMetricDate",
        Cell: ({ value }) => {
          return value ? (
            <>{format(Date.parse(value), "MMM do, yyyy hh:mm aa")}</>
          ) : (
            "--"
          );
        },
      },
      {
        // Last reminder date
        Header: "",
        accessor: "lastReminderDate",
        Cell: ({ value }) => {
          return value ? (
            <>{format(Date.parse(value), "MMM do, yyyy hh:mm aa")}</>
          ) : (
            "--"
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => (
          <div className="company__table__actions">
            <Button
              variant="text-link"
              size="xs"
              onClick={handleRowEdit(value)}
              className="company__table__edit"
            >
              <ion-icon name="pencil"></ion-icon>
            </Button>
            <Button
              variant="text-link"
              size="xs"
              onClick={handleRowDelete(value)}
              className="company__table__edit"
            >
              <ion-icon name="trash-outline"></ion-icon>
            </Button>
            <Button
              variant="text-link"
              size="xs"
              onClick={handleSendEmail(value)}
              className="company__table__edit"
            >
              <ion-icon name="mail-outline"></ion-icon>
            </Button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <div className="company">
      <PageHeader
        icon={<ion-icon name="business-outline"></ion-icon>}
        title="General Partners"
        subtitle="All registered gps"
      >
        <Link to="/add/gp">
          <Button type="button">Add GP</Button>
        </Link>
      </PageHeader>
      {lps && lps.list && lps.list.length ? (
        <div className="company__table">
          <div className="company__table__filter">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>

          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <BlankState
          text="No GPs added"
          icon={<img src={Company} alt="no comapny" />}
        />
      )}
    </div>
  );
};
