export default (
  state = {
    gp: {},
    selectedGp: {},
    isPostGpSuccess: false,
    isPostGpFail: false,
    updatedGp: {},
    deletedGp: {},
    isUpdatingGp: false,
    isPostingGp: false,
    isUpdatingGpFail: false,
    isUpdatingGpSuccess: false,
    isDeleteingGpSuccess: false,
    isDeleteingGpFail: false,
  },
  action
) => {
  switch (action.type) {
    case "POST_GP_START":
      return {
        ...state,
        isPostingGp: true,
        isPostGpSuccess: false,
        isPostGpFail: false,
      };

    case "POST_GP_SUCCESS":
      return {
        ...state,
        isPostingGp: false,
        isPostGpSuccess: true,
        gp: action.data,
        selectedGp: {},
      };

    case "POST_GP_FAIL":
      return { ...state, isPostGpFail: true, isPostingGp: false };

    case "RESET_POST_GP_STATUS":
      return {
        ...state,
        isPostGpSuccess: false,
        isPostGpFail: false,
        isUpdatingGpFail: false,
        isUpdatingGpSuccess: false,
        isDeleteingGpSuccess: false,
        isDeleteingGpFail: false,
      };

    case "GET_GP_BY_ID_LOADING":
      return {
        ...state,
        isFetchingGpByID: true,
      };

    case "GET_GP_BY_ID":
      return {
        ...state,
        selectedGp: !action.error ? action.payload : {},
        isFetchingGpByID: false,
      };

    case "UPDATE_GP_LOADING":
      return {
        ...state,
        isUpdatingGp: true,
        isUpdatingGpSuccess: false,
        isUpdatingGpFail: false,
      };

    case "UPDATE_GP":
      return {
        ...state,
        updatedGp: !action.error ? action.payload : {},
        isUpdatingGp: false,
        isUpdatingGpSuccess: !action.error ? true : false,
        isUpdatingGpFail: action.error ? true : false,
      };

    case "DELETE_GP_LOADING":
      return {
        ...state,
        isDeleteinGp: true,
        isDeleteingGpSuccess: false,
        isDeleteingGpFail: false,
      };

    case "DELETE_GP":
      return {
        ...state,
        deletedGp: !action.error ? action.payload : {},
        isDeleteingGp: false,
        isDeleteingGpSuccess: !action.error ? true : false,
        isDeleteingGpFail: action.error ? true : false,
      };

    default:
      return state;
  }
};
