import { combineReducers } from "redux";
import login from "../features/login/reducers";
import forgotPassword from "../features/forgotPassword/reducers";
import resetPassword from "../features/resetPassword/reducers";

import userAdd from "../features/usersAdd/reducers";
import users from "../features/users/reducers";
import layout from "../features/layout/reducers";
import companyAdd from "../features/companyAdd/reducers";
import fundAdd from "../features/fundsAdd/reducers";
import lpAdd from "../features/addLp/reducers";
import gpAdd from "../features/addGp/reducers";
import companies from "../features/companies/reducers";
import companyDashboards from "../features/companiesDashboard/reducers";
import funds from "../features/funds/reducers";
import lps from "../features/LPDashboard/reducers";
import gps from "../features/GPDashboard/reducers";
import changePassword from "./../features/changePassword/reducers";
import financialMetric from "./../features/metrices/reducers";
import metricForm from "./../features/metricForm/reducers";
import templates from "./../features/templates/reducers";
import templatesData from "./../features/templateForm/reducers";

const appReducer = combineReducers({
  login,
  forgotPassword,
  userAdd,
  users,
  layout,
  companyAdd,
  companies,
  changePassword,
  resetPassword,
  financialMetric,
  templates,
  templatesData,
  metricForm,
  fundAdd,
  funds,
  companyDashboards,
  lps,
  gps,
  lpAdd,
  gpAdd,
});

export default (state, action) => {
  if (action.type === "USER_LOGOUT_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};
