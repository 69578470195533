export default [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Textarea',
    value: 'textarea',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Amount',
    value: 'amount',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
  {
    label: 'Month Year',
    value: 'month_year',
  },
];
