import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from './../../../components/Loader';
import SideBar from '../../sidebar/components';

import { setCurrentCompanyById } from './../actions';

import config from '../../../config';

import './index.scss';

export default ({ children }) => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const layout = useSelector((state) => state.layout);
  const user = login.user;
  const companyId = localStorage.getItem(config.LOCAL_STORAGE_COMPANY_KEY);
  const [sidebarVisibility, setSideVisibility] = useState(false);

  // Fetch information of current company if not already fetching
  useEffect(() => {
    if (!login.isFetchingUser && companyId && user) {
      dispatch(setCurrentCompanyById(companyId));
    } else if (
      (!login.isFetchingUser &&
        !companyId &&
        user &&
        user.companies &&
        user.companies[0]) ||
      (companyId &&
        user &&
        user.companies &&
        user.companies[0] &&
        !user.companies.some((record) => record.id === companyId))
    ) {
      dispatch(setCurrentCompanyById(user.companies[0].id));
    }
  }, [dispatch, companyId, user, login.isFetchingUser]);

  const handleMenuClick = () => {
    setSideVisibility(true);
  };

  const handleCloseClick = () => {
    setSideVisibility(false);
  };

  if (
    login.isFetchingUser ||
    layout.isFetchingCompany ||
    !user ||
    !layout.company
  ) {
    return (
      <div className="layout__loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="layout">
      <SideBar visibility={sidebarVisibility} />
      <div className="layout__container">
        <div className="layout__container__menu">
          <button type="button" onClick={handleMenuClick}>
            <ion-icon name="menu-outline"></ion-icon>
          </button>
          {sidebarVisibility ? (
            <button type="button" onClick={handleCloseClick}>
              <ion-icon name="close-outline"></ion-icon>
            </button>
          ) : null}
        </div>
        {children}
      </div>
    </div>
  );
};
