import React, { useState } from 'react';
import BlankState from '../../../components/BlankState';
import NoData from './../../../assets/images/no-data.svg';
import { useSelector, useDispatch } from "react-redux";
import './index.scss';
import 'react-tabs/style/react-tabs.css';
import LPDetailView from './LPDetailView';
import LPListView from './LPListView';
import { useEffect } from 'react';

export default () => {
  const login = useSelector((state) => state.login);
  const user = login.user;
  const [selectedLP, setSelectedLP] = useState();

  useEffect(() => {
    if (user.role === "lp_admin" && user.lps && user.lps.length > 0) {
      setSelectedLP(user.lps[0]);
    }
  }, [user]);

  return (
    <div className="dashboard column flex">
      {selectedLP ? <LPDetailView setSelectedLP={setSelectedLP} selectedLP={selectedLP}/> : <LPListView setSelectedLP={setSelectedLP}/> }
    </div>
  );
};
