import axios from "axios";
import config from "../../../config";

const getCompaniesListStart = () => {
  return {
    type: "GET_COMPANIES_LIST_START",
  };
};

const getCompaniesListSuccess = ({ data }) => {
  return {
    type: "GET_COMPANIES_LIST_SUCCESS",
    data,
  };
};

const getCompaniesListFail = () => {
  return {
    type: "GET_COMPANIES_LIST_FAIL",
  };
};

const getCompanyMetricsFail = () => {
  return {
    type: "GET_COMPANY_METRICS_FAIL",
  };
};

const getCompanyMetricsStart = () => {
  return {
    type: "GET_COMPANY_METRICS_START",
  };
};

const getCompanyMetricsSuccess = ({ data }) => {
  return {
    type: "GET_COMPANY_METRICS_SUCCESS",
    data,
  };
};

export function getCompaniesList() {
  return (dispatch, getState) => {
    dispatch(getCompaniesListStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/company/all`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(getCompaniesListSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log("Failed to post user", ex);
        dispatch(getCompaniesListFail());
      });
  };
}

// Send reminder
export const sendReminder = (companyId, ccPartner) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SEND_REMINDER_LOADING",
      payload: true,
    });

    const { login } = getState();
    axios
      .post(
        `${config.API_URL}/company/${companyId}/reminder`,
        {
          ccPartner,
        },
        {
          headers: {
            Authorization: `Bearer ${login.token}`,
          },
        }
      )
      .then((resp) => {
        dispatch({
          type: "SEND_REMINDER",
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log("Failed to send reminder", ex);
        dispatch({
          type: "SEND_REMINDER",
          payload: ex,
          error: true,
        });
      });
  };
};

export const resetReminderStatus = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_REMINDER_STATUS", payload: false });
  };
};


export function getCompanyMetrics(companyId) {
  return (dispatch, getState) => {
    dispatch(getCompanyMetricsStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/company/${companyId}/metrics`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        console.log(resp, "responsessss");
        dispatch(getCompanyMetricsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log("Failed to get Fund metrics", ex);
        dispatch(getCompanyMetricsFail());
      });
  };
}